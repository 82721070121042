import sizeUtils from '@buzzfeed/adlib/dist/module/services/size/standalone';
import { sizes } from '@buzzfeed/adlib/dist/module/bindings/react';
import adsConfig from '../../../constants/ads-config';
import AdPlaceholder from './AdPlaceholder';
import AdUnit from '../AdUnit';

export const AdInFeed = ({
  className = '',
  config,
  renderPlaceholderOnly = false,
  type,
}) => {
  let cfg;
  if (config) {
    cfg = config;
  } else {
    cfg = { ...adsConfig[type] };
  }
  const extraClasses = `Ad--feed ${className}`.trim();
  cfg.size = sizeUtils.exclude(
    cfg.size,
    sizes.PROGRAMMATIC_SUPER_LEADERBOARD,
    sizes.PROGRAMMATIC_BILLBOARD
  );

  return (
    <AdPlaceholder renderChildren={!renderPlaceholderOnly}>
      <AdUnit config={cfg} className={extraClasses} stickyWithinPlaceholder={true} />
    </AdPlaceholder>
  );
};
