import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { localUserEntries } from '../../../utils/user-entries';
import { bfUrl } from '../../../constants';
import { useTrackingContext } from '../../../hooks';
import { Sponsor } from '../../Sponsor';
import styles from '../funmodule.module.scss';

const userAnswers = localUserEntries({ namespace: 'localUserVotes' });

const voteApiRequest = async ({ answerId, objectId }) => {
  const url = `${bfUrl}/polls-api/v2/content-object/${objectId}`;
  const options = {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  }

  /**
   * When answerID is provided, it means the user has voted (chosen an answer) and the request will
   * need to POST the response to the API.
   */
  if (typeof answerId === 'number') {
    options.body = JSON.stringify({ result: answerId });
    options.method ='POST';
  }

  /**
   * @todo
   * This will change depending on how we integrate error boundaries
   */
  try {
    const pollsResponse = await fetch(url, options);

    if (!pollsResponse.ok) {
      throw new Error(
        `Fetch request for '${url}' failed with status: ${pollsResponse.status} - ${pollsResponse.statusText}`
      );
    }
    return await pollsResponse.json();
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const Voting = (props) => {
  const { content, isTrackable, trackingData, index: subunitIndex, id, sponsorship } = props;
  const { trackContentAction } = useTrackingContext();
  const { answers = [], question } = content;

  const [userVote, setUserVote] = useState(null);
  const [pollResults, setPollResults] = useState(null);

  const handleActiveAnswer = useCallback((index) => async () => {
    const answerId = answers[index]?.id;

    if (userVote === null && !pollResults && typeof answerId === 'number') {
      const response = await voteApiRequest({
        answerId,
        objectId: id,
      });

      if (response) {
        userAnswers.set(`content-object:${id}:${answerId}`);
        setUserVote(answerId);
        setPollResults(response);

        if (isTrackable) {
          trackContentAction({
            ...trackingData,
            item_type: 'button',
            item_name: id,
            position_in_subunit: typeof subunitIndex === 'number' ? subunitIndex : undefined,
            action_type: 'select',
            action_value: answers[index].text,
            subunit_name: 'whats_your_vote',
            subunit_type: 'component',
          })
        }
      }
    }
  }, [answers, id, pollResults, userVote]);

  /**
   * Checks if the user has already voted. If so, update state with thier previous vote.
   */
  useEffect(() => {
    const votes = async () => {
      const answerKey = userAnswers.get(`content-object:${id}`);

      if (answerKey) {
        const answerId = parseFloat(answerKey.split(':').pop());

        setUserVote(isNaN(answerId) ? null : answerId);

        const response = await voteApiRequest({
          objectId: id,
          type: 'content-object',
        });

        setPollResults(response);
      }
    };
    votes();
  }, [id]);

  if(!answers?.length || !question?.length) {
    return null;
  }

  return (
    <article
      className={styles.container}
      style={{
      '--sponsorTextColor': sponsorship?.data?.colors?.text,
      '--sponsorAccentColor': sponsorship?.data?.colors?.accent,
      '--sponsorBackgroundColor': sponsorship?.data?.colors?.background,
    }}>
      <div className={styles.headerWrap}>
        {sponsorship?.sponsor && (
          <Sponsor
            data={sponsorship.sponsor}
            isPromoted={!!sponsorship?.data?.colors?.text}
            trackingData={{
              ...trackingData,
              item_type: 'text',
              item_name: 'presented_by',
              subunit_name: 'whats_your_vote',
              subunit_type: 'component',
              target_content_type: 'url',
              target_content_url: sponsorship?.sponsor?.client_data?.url,
            }}
          />
        )}
        <h2 className={styles.subTitle}>WHAT’S YOUR VOTE?</h2>
      </div>
      <h3 className={styles.title}>{question}</h3>
      <ul className={`${styles.votingAnswers} ${userVote !== null && pollResults ? styles.isVoted : ''} ${userVote !== null && !pollResults ? styles.loading : ''}`}>
        {answers.map((answer, index) => (
          <li
            className={userVote === answer.id ? styles.active : ''}
            key={answer.id}
            style={{'--votingAnswerValue': `${Math.round(pollResults?.percentages?.[answer.id] || 0)}%`}}
          >
            <button
              className={styles.votingAnswer}
              onClick={handleActiveAnswer(index)}
              aria-label={answer.text}
            >
              <span className={styles.answerTitle}>{answer.text}</span>
              {answer.image && (
                <span className={styles.answerImage}>
                  <img src={answer.image} alt={answer.text} />
                </span>
              )}
              <span className={styles.answerValue}>{Math.round(pollResults?.percentages?.[answer.id] || 0)}%</span>
            </button>
          </li>
        ))}
      </ul>
    </article>
  );
};

Voting.propTypes = {
  content: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      text: PropTypes.string,
    })),
    question: PropTypes.string,
  }),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isTrackable: PropTypes.bool,
  sponsorship: PropTypes.object,
  trackingData: PropTypes.object,
};

export default Voting;
