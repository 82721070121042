import React from 'react';
import FeedCard from '../index';
import styles from './splashCard.module.scss';

const getThumbnailType = (ratio = '') => {
  const ratioParams = ratio.split('/');
  return ratioParams?.length > 1 && ratioParams[0] === ratioParams[1] ? 'square' : 'standard';
};

export const SplashCard = ({
  item,
  index = 0,
  imageRatio = '3/2',
  imageRatioTablet = '3/2',
  imageRatioDesktop = '3/2',
  showTagLabel = false,
  isPrimary = true,
  className = '',
  isTrackable= false,
  trackingData = {},
}) => {
  const baseTrackingData = {
    item_type: 'card',
    item_name: item?.id,
    target_content_id: item?.id,
    target_content_type: 'buzz',
    ...trackingData
  };

  if (!item) return null;

  const cardClassName = [
    styles.splashCard,
    isPrimary ? styles.primaryPost : styles.secondaryPost,
    index > 0 ? styles.hasIndex : '',
    styles[className]
  ].join(' ');

  const thumbnailType = getThumbnailType(imageRatio);
  const thumbnailTabletType = getThumbnailType(imageRatioTablet);
  const thumbnailDesktopType = getThumbnailType(imageRatioDesktop);

  return (
    <FeedCard
      className={cardClassName}
      isTrackable={isTrackable}
      trackingData={baseTrackingData}
    >
      <FeedCard.Link
        url={item.url}
        isTrackable={isTrackable}
        className={styles.linkWrap}
        trackingData={baseTrackingData}
      >
        <figure className={styles.thumbnail}>
          <picture>
            {thumbnailType !== thumbnailTabletType && <source
              srcSet={item.thumbnail?.[thumbnailTabletType]?.url}
              media='(min-width: 40rem)'
            />}
            {thumbnailTabletType !== thumbnailDesktopType && <source
              srcSet={item.thumbnail?.[thumbnailDesktopType]?.url}
              media='(min-width: 64rem)'
            />}
            <FeedCard.Thumbnail
              thumbnail={item.thumbnail?.[thumbnailType]?.url}
              alt={item.thumbnail?.[thumbnailType]?.alt}
              ratio={imageRatio}
              ratioTablet={imageRatioTablet}
              ratioDesktop={imageRatioDesktop}
            />
          </picture>
        </figure>
        <div className={styles.textWrapper}>
          {showTagLabel && (
            <FeedCard.InfoLabel
              className={styles.tagLabel}
              label="Popular"
            />
          )}
          {index > 0 && <FeedCard.IndexLabel className={styles.index}>{index}</FeedCard.IndexLabel>}
          <h3 className={styles.heading}>{item.title}</h3>
        </div>
      </FeedCard.Link>
      <div className={styles.statsWrapper}>
        <FeedCard.Reactions
          className={styles.reactions}
          contentId={Number(item.id)}
          contentType="buzz"
          data={item.contentReactions}
          isTrackable={isTrackable}
          trackingData={baseTrackingData}
        />
        {(item?.pageviews?.count > 4 || item?.comments?.count > 4) && (
          <FeedCard.Link
            className={styles.statsLink}
            key="statsLink"
            url={item.url}
            isTrackable={isTrackable}
            trackingData={baseTrackingData}
          >
            <FeedCard.Stats className={styles.stats} viewsCount={item?.pageviews?.countFormatted} commentsCount={item?.comments?.countFormatted} />
          </FeedCard.Link>
        )}
      </div>
    </FeedCard>
  );
};

export default SplashCard;
