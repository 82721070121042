import { useContext } from 'react';
import { ChevronRight } from '../../../icons/ChevronRight';
import FadeCarousel, { FadeContext } from '../FadeCarousel';
import Sponsor from '../../Sponsor';
import styles from '../funmodule.module.scss';
import { useViewImpression } from '../../../hooks';
import LinkWithTracking from '../../LinkWithTracking';

export const Article = ({
  headline = {},
  id,
  sponsor = {},
  thumbnail,
  title,
  url,
  position = 0,
  trackingData = {},
}) => {
  const defaultTrackingData = {
    ...trackingData,
    item_type: 'card',
    item_name: id.toString(),
    position_in_unit: 3,
    position_in_subunit: position,
    target_content_type: 'buzz',
    target_content_id: id,
    subunit_name: 'article_carousel',
    subunit_type: 'component',
  };
  const sponsoredTrackingData = {
    ...defaultTrackingData,
    target_content_type: 'url',
    target_content_url: sponsor?.client_data?.url,
    item_type: 'text',
    item_name: 'presented_by',
  };

  const { currentIndex } = useContext(FadeContext);

  // impression tracking
  const { setObservable } = useViewImpression({
    condition: position === currentIndex,
    trackingData: defaultTrackingData,
  }, [currentIndex]);

  return (
    <article
      className={`${styles.container} ${styles.twoColumns} ${styles.article}`}
      ref={obvEl => position === currentIndex ? setObservable(obvEl) : null}
    >
      {thumbnail &&
        <LinkWithTracking
          href={url}
          className={`${styles.imageWrapper} ${styles.imageWrapperWide}`}
          commonTrackingData={{
            ...defaultTrackingData,
            target_content_url: url,
          }}
        >
          <img src={thumbnail.standard?.url} alt={thumbnail.standard?.alt} loading="lazy"/>
        </LinkWithTracking>
      }
      <div className={styles.textWrapper}>
        { !!sponsor?.display_name && (
          <Sponsor data={sponsor} className="xs-hide lg-flex" trackingData={sponsoredTrackingData} />
        )}
        {headline.text && (
          <h2 className={styles.title}>
            {!!headline.url?.length ? (
              <LinkWithTracking
                href={headline.url}
                commonTrackingData={{
                  ...defaultTrackingData,
                  item_type: 'text',
                  item_name: headline.text,
                  target_content_type: 'url',
                  target_content_id: headline.url,
                }}
              >
                {headline.text}
              </LinkWithTracking>
            ) : headline.text}
          </h2>
        )}
        <div className={styles.text}>
          <p>
            <LinkWithTracking href={url}
              commonTrackingData={{
              ...defaultTrackingData,
              target_content_url: url,
            }}>
              {title}
            </LinkWithTracking>
          </p>
        </div>
        <LinkWithTracking
          href={url}
          className={styles.readMore}
          commonTrackingData={{
            ...defaultTrackingData,
            target_content_url: url,
          }}
        >
          Read The Post
          <ChevronRight
            aria-hidden="true"
            className={`svg-3 ${styles.caretIcon}`}
          />
        </LinkWithTracking>
      </div>
    </article>
  );
};

const ArticleCarousel = ({
  content = {},
  sponsorship = {},
  trackingData = {},
}) => {
  const { posts = [], ...rest } = content;
  const sponsor = sponsorship?.sponsor;
  const props = { sponsor, ...rest };


  if (posts.length == 1) {
    return (
      <Article {...posts[0]} {...props} />
    );
  }

  return (
    <>
      <div className="xs-hide lg-block">
        <FadeCarousel>
          {posts.map((post, idx) => {
            return (
              <Article
                key={post.id}
                position={idx}
                trackingData={trackingData}
                {...post}
                {...props}
              />);
            }
          )}
        </FadeCarousel>
      </div>
      <div className="lg-hide">
        {!!sponsor?.display_name?.length && (
          <Sponsor
            data={sponsor}
            className="lg-hide"
            trackingData={{
              ...trackingData,
              item_type: 'text',
              item_name: 'presented_by',
              subunit_name: 'article_carousel',
              subunit_type: 'component',
              target_content_type: 'url',
              target_content_url: sponsor?.client_data?.url,
            }}
          />
        )}
        {props?.headline?.text && (
          <h2 className={`${styles.heading} lg-hide`}>
            {!!props?.headline.url?.length ? (
              <LinkWithTracking
                href={props.headline.url}
                commonTrackingData={{
                  ...trackingData,
                  item_type: 'text',
                  item_name: props.headline.text,
                  target_content_type: 'url',
                  target_content_id: props.headline.url,
                  position_in_unit: 3,
                  subunit_name: 'article_carousel',
                  subunit_type: 'component',
                }}
              >{props.headline.text}</LinkWithTracking>
            ) : props.headline.text}
          </h2>
        )}
        <div className={styles.scrollableContainer}>
          {posts.map((post, idx) => {
            return (
              <div className={styles.slide} key={`article-wrapper-${idx}`}>
                <Article
                  key={post.id}
                  position={idx}
                  trackingData={trackingData}
                  {...post}
                  {...props}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ArticleCarousel;
