import FeedsButton from '../../FeedsButton';
import LinkWithTracking from '../../LinkWithTracking';
import { ChevronRight } from '../../../icons/ChevronRight';
import { useTrackingContext, useViewImpression } from '../../../hooks';
import styles from '../funmodule.module.scss';

export const AddYoursDiscussion = ({ content, isTrackable, trackingData, index }) => {
  const { question, post, comments = {} } = content;

  const { trackInternalLink } = useTrackingContext();
  const commonTrackingData = {
    ...trackingData,
    item_type: 'button',
    item_name: 'add_your_answer',
    target_content_type: 'buzz',
    target_content_id: post.id,
    position_in_subunit: index ? index : undefined,
    subunit_name: 'discussion_highlight',
    subunit_type: 'component',
  };

  const repliesAmount = !!comments?.count && (
    <LinkWithTracking
      className={styles.replies}
      href={post.canonical_url}
      commonTrackingData={{
        ...commonTrackingData,
        item_type: 'text',
      }}>
        {`${comments.countFormatted} repl${comments.count > 1 ? 'ies' : 'y'}`} <ChevronRight />
    </LinkWithTracking>
  );

  const { setObservable } = useViewImpression({
    trackingData: commonTrackingData
  });
  const attachImpressionObserver = (el) => {
    if (!isTrackable) {
      return;
    }
    setObservable(el);
  }

  const handleAddAnswerClick = () => {
    if (isTrackable) {
      trackInternalLink(commonTrackingData);
    }
  };

  return (
    <div className={`${styles.container} ${styles.addDiscussion}`}>
      <h2 className={styles.subTitle}>JOIN THE DISCUSSION</h2>
      <h3 className={styles.title}>{question}</h3>
      <div className={`${styles.repliesWrap} xs-flex xs-flex-align-center`} ref={obvEl => attachImpressionObserver(obvEl)}>
        <FeedsButton
          title={!!comments?.count ? 'Add Your Answer' : 'Be The First To Comment'}
          onClick={handleAddAnswerClick}
          url={post.canonical_url}
        />
        {repliesAmount}
      </div>
    </div>
  );
};

export default AddYoursDiscussion;
