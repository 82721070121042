import PropTypes from 'prop-types';
import AddYoursDiscussion from './AddYoursDiscussion';
import ArticleCarousel from './ArticleCarousel';
import InOut from './InOut';
import Voting from './Voting';
import TopComment from './TopComment';
import styles from './funmodule.module.scss';

const FunModuleComponents = {
  voting: Voting,
  discussion_question: AddYoursDiscussion,
  top_comment: TopComment,
  article_carousel: ArticleCarousel,
  in_out: InOut,
};

const zonesWithColumnContainer = [
  'discussions',
];

const getContainerClassName = (name) => {
  if (zonesWithColumnContainer.includes(name)) {
    return `${styles.columnContainer} ${styles.notSingleColumn}`
  }

  return styles.scrollableContainer;
};

export const FunModule = ({
  className = '',
  data = [],
  isTrackable = false,
  trackingData = {}
}) => {
  const { items = [] } = data;

  const zoneName = data?.name || '';
  const containerClassName = getContainerClassName(zoneName);

  if (items.length === 1) {
    const { object_type, ...props } = items[0];
    const Component = FunModuleComponents[object_type];
    return (
      <section className={className}>
        <Component
          {...props}
          isTrackable={isTrackable}
          trackingData={trackingData}
          index={0}
        />
      </section>
    );
  }

  /**
   * `items` in `data` represents all feed items within a zone. When it is defined, we will need to
   * render a parent container for the list of fun module feed items.
   */
  return (
    <section className={className}>
      <div className={containerClassName}>
        {items.map(({ object_type, ...props }, index) => {
          const Component = FunModuleComponents[object_type];
          return (
            <div className={styles.slide} key={index}>
              <Component
                {...props}
                isTrackable={isTrackable}
                trackingData={{...trackingData, position_in_subunit: index}}
                index={index}
              />
            </div>
          )
        })}
      </div>
    </section>
  );
};

FunModule.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
};

export default FunModule;
